// components/Dashboard.tsx
import React, { useState } from 'react';
import {
    Box,
    Drawer,
    AppBar,
    Toolbar,
    List,
    Typography,
    IconButton,
    Container,
    Grid,
    Divider,
    Paper,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListItemButton,
    Card,
    CardContent,
} from '@mui/material';
import {
    Menu as MenuIcon,
    Home as HomeIcon,
    People as PeopleIcon,
    Description as DescriptionIcon,
    Logout as LogoutIcon,
} from '@mui/icons-material';
import { Theme } from '@mui/material/styles';
import { UserAttributes } from "./UserContext";
import Profile from "./Profile";
import RegisterUser from "./RegisterUser";
import SetElectricityBill from "./SetElectricityBill";
import SearchElectricityBill from "./SearchElectricityBill";
import ElectricityRateForm from "./ElectricityRateForm";
import ProfileMenu from "./ProfileItem"

interface DashboardProps {
    user: UserAttributes;
    onSignOut: () => void;
}

type MenuPage = 'home' | 'users' | 'bills';

// メインのダッシュボード画面のコンテンツ
const DashboardContent: React.FC<{ user: UserAttributes }> = ({ user }) => {
    return (
        <Container maxWidth="lg">
            <Paper>
                <SearchElectricityBill user={user}/>
            </Paper>
        </Container>
    );
};

// ユーザー管理画面
const UserManagementContent: React.FC<{ user: UserAttributes }> = ({ user }) => {
    return (
        <Container maxWidth="lg">
            <Profile user={user} />
            <Typography variant="h4" gutterBottom>
                ユーザー管理
            </Typography>
            <Paper sx={{ p: 2 }}>
                <RegisterUser onRegistrationSuccess={() => { }} />
            </Paper>
        </Container>
    );
};

// 電気使用量設定画面
const BillSettingsContent: React.FC = () => {
    const [refreshData, setRefreshData] = useState<number>(0);

    // 登録成功時のコールバック関数
    const handleRegistrationSuccess = () => {
        // データを最新化するためにカウンターをインクリメント
        setRefreshData((prev) => prev + 1);
        console.log("電気代の登録成功");
    };

    return (
        <Container maxWidth="lg">
            <Typography variant="h4" gutterBottom>
                電気設定
            </Typography>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper sx={{ p: 2 }}>
                        <Typography variant="h6" gutterBottom>
                            電気料金設定
                        </Typography>
                        <SetElectricityBill onRegistrationSuccess={handleRegistrationSuccess} />
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper sx={{ p: 2 }}>
                        <Typography variant="h6" gutterBottom>
                            料金基本レート設定
                        </Typography>
                        <ElectricityRateForm />
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
};

const Dashboard: React.FC<DashboardProps> = ({ user, onSignOut }) => {
    const [open, setOpen] = useState(true);
    const [currentPage, setCurrentPage] = useState<MenuPage>('home');

    const drawerWidth = 240;
    const appBarHeight = 64;
    const collapsedWidth = 56;

    const baseMenuItems = [
        { text: 'ホーム', icon: <HomeIcon />, page: 'home' as MenuPage, requireAdmin: false },
        { text: 'ユーザー管理', icon: <PeopleIcon />, page: 'users' as MenuPage, requireAdmin: true },
        { text: '電気使用量設定', icon: <DescriptionIcon />, page: 'bills' as MenuPage, requireAdmin: true },
    ];
    
    const menuItems = baseMenuItems.filter(item => !item.requireAdmin || user.admin === true);

    const renderContent = () => {
        if (!user.admin) {
            if (currentPage === 'users' || currentPage === 'bills') {
                setCurrentPage('home');
                return <DashboardContent user={user} />;
            }
        }
        
        switch (currentPage) {
            case 'home':
                return <DashboardContent user={user} />;
            case 'users':
                return <UserManagementContent user={user} />;
            case 'bills':
                return <BillSettingsContent />;
            default:
                return <DashboardContent user={user} />;
        }
    };

    return (
        <Box sx={{ display: 'flex', minHeight: '100vh' }}>
            <AppBar
                position="fixed"
                sx={{
                    zIndex: (theme: Theme) => theme.zIndex.drawer + 1,
                    height: appBarHeight,
                    transition: (theme: Theme) => theme.transitions.create(['width', 'margin'], {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.leavingScreen,
                    }),
                }}
            >
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={() => setOpen(!open)}
                        sx={{ mr: 2 }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" sx={{ flexGrow: 1 }}>
                        Bill Management System
                    </Typography>
                    <ProfileMenu user={user} onSignOut={onSignOut} />
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                open={open}
                sx={{
                    width: open ? drawerWidth : 72,
                    flexShrink: 0,
                    whiteSpace: 'nowrap',
                    boxSizing: 'border-box',
                    '& .MuiDrawer-paper': {
                        width: open ? drawerWidth : 72,
                        transition: (theme: Theme) => theme.transitions.create('width', {
                            easing: theme.transitions.easing.sharp,
                            duration: theme.transitions.duration.enteringScreen,
                        }),
                        overflowX: 'hidden',
                        backgroundColor: (theme: Theme) => theme.palette.grey[100],
                    },
                }}
            >
                <Toolbar />
                <Divider />
                <List>
                    {menuItems.map((item) => (
                        <ListItemButton
                            key={item.text}
                            onClick={() => setCurrentPage(item.page)}
                            selected={currentPage === item.page}
                            sx={{
                                minHeight: 48,
                                px: 2.5,
                                '&:hover': {
                                    backgroundColor: (theme: Theme) => theme.palette.action.hover,
                                },
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                }}
                            >
                                {item.icon}
                            </ListItemIcon>
                            <ListItemText
                                primary={item.text}
                                sx={{ opacity: open ? 1 : 0 }}
                            />
                        </ListItemButton>
                    ))}
                </List>
            </Drawer>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    p: 3,
                    mt: `${appBarHeight}px`,
                    backgroundColor: (theme: Theme) => theme.palette.background.paper,
                    width: `calc(100% - 72px)`,  // 閉じた時のドロワー幅で固定
                    position: 'absolute',         // 絶対位置指定
                    right: 0,                     // 右端に固定
                    left: '72px',                 // 左端をドロワーの最小幅に固定
                }}
            >
                {renderContent()}
            </Box>
        </Box>
    );
};

export default Dashboard;