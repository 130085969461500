import React, { useState, useEffect } from "react";
import { post, get } from "aws-amplify/api";
import { fetchAuthSession } from "aws-amplify/auth";
import FileUploader from "./FileUploader";
import { Alert, Snackbar } from "@mui/material";

interface SetElectricityBillProps {
  onRegistrationSuccess: () => void;
}

interface BuildingInfo {
  name?: string;
  roominfo?: {
    room_number?: string;
    elec?: {
      lighting?: string[];
      motive?: string[];
    };
  }[];
}

interface MeterReading {
  value: string;
  unit: string;
  confidence: string;
  meter_type: string;
  inputType: 'manual' | 'upload';
}

interface MeterReadings {
  [key: string]: MeterReading;
}

interface UploadedData {
  [key: string]: {
    unit: string;
    // その他の必要なプロパティ
  };
}

const SetElectricityBill: React.FC<SetElectricityBillProps> = ({
  onRegistrationSuccess,
}) => {
  const [year, setYear] = useState<string>("");
  const [month, setMonth] = useState<string>("");
  const [roomNumber, setRoomNumber] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const [buildingInfo, setBuildingInfo] = useState<BuildingInfo | null>(null);
  const [meterReadings, setMeterReadings] = useState<MeterReadings>({});
  const [uploadedData, setUploadedData] = useState<UploadedData | null>(null);

  useEffect(() => {
    const fetchBuildingInfo = async () => {
      try {
        const session = await fetchAuthSession();
        const token = session.tokens?.idToken?.toString();
        if (!token) {
          throw new Error("認証トークンが利用できません");
        }
        const restOperation = get({
          apiName: "billapi",
          path: "/get_building_info",
        });
        const { body, statusCode } = await restOperation.response;
        const result = (await body.json()) as BuildingInfo
        if (statusCode === 200) {
          setBuildingInfo(result);
        } else {
          console.error("ビル情報の取得に失敗しました:", result);
          setError("ビル情報の取得に失敗しました。");
        }
      } catch (err) {
        console.error("ビル情報の取得エラー:", err);
        setError("ビル情報の取得中にエラーが発生しました。");
      }
    };

    fetchBuildingInfo();
  }, []);

  useEffect(() => {
    if (roomNumber && buildingInfo) {
      const room = buildingInfo.roominfo?.find(r => r.room_number === roomNumber);
      if (room) {
        const newMeterReadings: MeterReadings = {};
        room.elec?.lighting?.forEach((meter) => {
          newMeterReadings[`${meter}`] = { 
            value: "", 
            unit: "kWh", 
            confidence: "", 
            meter_type: "electric power meter", 
            inputType: 'manual' 
          };
        });
        room.elec?.motive?.forEach((meter) => {
          newMeterReadings[`${meter}`] = { 
            value: "", 
            unit: "kWh", 
            confidence: "", 
            meter_type: "electric power meter", 
            inputType: 'manual' 
          };
        });
        setMeterReadings(newMeterReadings);
      }
    }
  }, [roomNumber, buildingInfo]);

  const handleMeterReadingChange = (meter: string, value: string) => {
    setMeterReadings(prev => ({
      ...prev,
      [meter]: { ...prev[meter], value, inputType: 'manual' }
    }));
  };

  const handleUploadSuccess = (meter: string, result: MeterReading) => {
    setMeterReadings(prev => ({
      ...prev,
      [meter]: { ...result, inputType: 'upload' }
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError("");

    if (
      !year ||
      !month ||
      !meterReadings ||
      !roomNumber
    ) {
      setError("すべての項目を入力してください。");
      return;
    }

    try {
      const session = await fetchAuthSession();
      const token = session.tokens?.idToken?.toString();
      if (!token) {
        throw new Error("認証トークンが利用できません");
      }

      const submissionData = Object.entries(meterReadings).reduce((acc, [key, reading]) => {
        acc[key] = reading.value;
        return acc;
      }, {} as { [key: string]: string });

      const restOperation = post({
        apiName: "billapi",
        path: "/electricity-bill",
        options: {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: {
            year,
            month,
            roomNumber,
            meterReadings: submissionData,
          },
        },
      });
      const { body, statusCode } = await restOperation.response;
      const result = (await body.json())
      if (statusCode !== 200) {
        console.log("電気代登録失敗:", result);
      } else {
        console.log("電気代登録成功:", result);
        setSuccessMessage("電気代の登録が完了しました");
        setShowSuccess(true);
        // フォームをリセット
        setYear("");
        setMonth("");
        setRoomNumber("");
        setMeterReadings({});
        onRegistrationSuccess();
      }
    } catch (err) {
      console.error("電気代登録エラー:", err);
      setError("電気代の登録中にエラーが発生しました。");
    }
  };
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 5 }, (_, i) => currentYear - i);
  const months = Array.from({ length: 12 }, (_, i) => i + 1);

  const handleCloseSuccess = () => {
    setShowSuccess(false);
  };

  return (
    <div className="register-container">
      <h2>電気代登録</h2>
      <Snackbar
        open={showSuccess}
        autoHideDuration={6000}
        onClose={handleCloseSuccess}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSuccess} severity="success" sx={{ width: '100%' }}>
          {successMessage}
        </Alert>
      </Snackbar>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="roomNumber">部屋番号:</label>
          <select
            id="roomNumber"
            value={roomNumber}
            onChange={(e) => setRoomNumber(e.target.value)}
            required
          >
            <option value="">選択してください</option>
            {buildingInfo?.roominfo?.map((room) => (
              <option key={room.room_number} value={room.room_number}>
                {room.room_number}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label htmlFor="year">年:</label>
          <select
            id="year"
            value={year}
            onChange={(e) => setYear(e.target.value)}
            required
          >
            <option value="">選択してください</option>
            {years.map((y) => (
              <option key={y} value={y}>
                {y}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label htmlFor="month">月:</label>
          <select
            id="month"
            value={month}
            onChange={(e) => setMonth(e.target.value)}
            required
          >
            <option value="">選択してください</option>
            {months.map((m) => (
              <option key={m} value={m}>
                {m}
              </option>
            ))}
          </select>
        </div>
        {Object.entries(meterReadings).map(([meter, reading]) => (
          <div key={meter}>
            {/* <label htmlFor={meter}>{meter.split('_')[0]}（{meter.split('_')[1]}）:</label> */}
            <label htmlFor={meter}>{meter}</label>
            <input
              type="number"
              id={meter}
              value={reading.value}
              onChange={(e) => handleMeterReadingChange(meter, e.target.value)}
              required
            />
            <span>{reading.unit}</span>
            {reading.inputType === 'upload' && (
              <span>（信頼度: {reading.confidence}）</span>
            )}
            <FileUploader
              type="ELECTRO"
              year={year}
              month={month}
              room={roomNumber}
              onUploadSuccess={(result) => handleUploadSuccess(meter, result)}
            />
          </div>
        ))}
        {error && <p className="error-message">{error}</p>}
        <button type="submit">電気代を登録</button>
      </form>
    </div>
  );
};

export default SetElectricityBill;
